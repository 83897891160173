var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:_vm.s.container,style:({
        height: `${_vm.size}px`,
        width: `${_vm.size}px`,
    })},[_c('div',{class:_vm.s.border},[(_vm.needCross)?_c('Icon',{class:_vm.s.icon,attrs:{"icon":"cross"},on:{"clicked":function($event){return _vm.$emit('close')}}}):_vm._e(),_c('div',{class:_vm.s.helperOne,style:({
                width: `${_vm.size - 24}px`,
                height: `${_vm.size - 84}px`,
            })}),_c('div',{class:_vm.s.helperTwo,style:({
                height: `${_vm.size - 24}px`,
                width: `${_vm.size - 84}px`,
            })}),_c('qrcode-vue',{class:_vm.s.qr,attrs:{"size":_vm.qrSize,"value":_vm.value,"level":"H","render-as":"svg","background":_vm.qrBackground,"foreground":_vm.qrForeground}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }